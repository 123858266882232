import * as React from "react"
import PropTypes from "prop-types"

import logo from "../images/favicon.png"

import "../scss/style.scss"

const Layout = ({ children }) => {
  return (
    <div className="container">
      <div>
        <main>{children}</main>
        <footer className="footer">
          <div className="footer-section">
            <p className="footer-link">
              <a href="mailto:info@fitlab.com">info@fitlab.com</a>
            </p>
            <div className="footer-logo">
              <img src={logo} alt="footer-logo" />
            </div>
            <p className="copyright">
              Copyright ⓒ 2021 FitLab | All Rights Reserved
            </p>
          </div>
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
