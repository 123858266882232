import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function Seo({
  description = "A Global Fitness &amp; Sport Lifestyle Company",
  lang,
  meta,
  title = "FitLab",
  metaTail = "FitLab",
}) {
  const metaSeparator = "|"
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${title} ${metaSeparator} ${metaTail}`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: `${title} ${metaSeparator} ${metaTail}`,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
